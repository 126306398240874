import NextLink from 'next/link';
import cn from 'classnames';

import { hideMainNavigationMenuGroups } from '@utilities';
import { Icon } from '@components/Icon';
import { MenuLink } from '@interfaces';

import classes from './MenuLinks.classes';

export interface MenuLinksProps {
  menuLinks?: MenuLink[];
  parent: string;
}

function MenuLinks({ menuLinks, parent }: MenuLinksProps): JSX.Element | null {
  if (!menuLinks?.length) return null;

  return (
    <ul className={cn('main-navigation-menu-group', classes.ul)}>
      {menuLinks.map((menuLink: MenuLink) => (
        <li key={menuLink.key} className={classes.li}>
          <NextLink
            href={menuLink.url}
            className={classes.link}
            data-element-click={`link[${menuLink.name}]_MenuLinks[${parent}]`}
            onClick={() => hideMainNavigationMenuGroups()}
          >
            <Icon icon={menuLink.icon || 'fak fa-click-dealer'} className={classes.icon} />
            {menuLink.name}
          </NextLink>
        </li>
      ))}
    </ul>
  );
}

export default MenuLinks;
