import { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { FormColumn, FormField, FormFieldset, FormPage } from '@interfaces/forms';

async function validateForm(
  pages: FormPage[],
  getValues: UseFormGetValues<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  trigger: UseFormTrigger<FieldValues>,
): Promise<boolean> {
  const results: boolean[] = [];

  await Promise.all(
    pages.map(async (page: FormPage) => {
      await Promise.all(
        page.fieldsets.map(async (fieldset: FormFieldset) => {
          await Promise.all(
            fieldset.columns.map(async (column: FormColumn) => {
              await Promise.all(
                column.fields.map(async (field: FormField) => {
                  setValue(field.alias, getValues(field.alias), { shouldDirty: true });
                  results.push(await trigger(field.alias));
                }),
              );
            }),
          );
        }),
      );
    }),
  );

  return results.every((result) => result);
}
export default validateForm;
