import { GlobalProps, MainNavigation, MenuGroup, MenuLink, Product, ProductGroup } from '@interfaces';

import buildTrackingConfig from './builders/buildTrackingConfig';
import buildContactLinks from './builders/buildContactLinks';
import buildFooterLogos from './builders/buildFooterLogos';
import buildSocialLinks from './builders/buildSocialLinks';
import buildMenuGroups from './builders/buildMenuGroups';
import buildLegalLinks from './builders/buildLegalLinks';
import buildButton from './builders/buildButton';
import buildImage from './builders/buildImage';

const getInitialProps = async () => {
  const result = await fetch(`${process.env.API_URL}/site-config`);
  const data = await result.json();
  const {
    affiliateLogoImage,
    affiliateLogoUrl,
    affiliateLogoImageAltText,
    companyName,
    contactPhoneNumber,
    contactEmailAddress,
    contactMessengerAccount,
    contactWhatsAppNumber,
    footerNavigation,
    legalNavigation,
    logo,
    mainNavigation: mainNav,
    openGraphType,
    openGraphLocale,
    openGraphUrl,
    openGraphSitename,
    openGraphImages,
    socialMediaAccounts,
    twitterHandle,
    twitterSite,
    twitterCardType,
    livechatId,
    redirects,
  } = data.content;

  const siteLogo = buildImage({ url: logo?.url, name: logo?.name, alt: logo?.altText });

  const mainNavigation: MainNavigation = {
    productsMenuGroup: mainNav?.productsMenuGroup || undefined,
    menuGroups: buildMenuGroups({ menuGroups: mainNav?.menuGroups }),
  };

  if (mainNav.button) {
    const mainNavButton = buildButton({ ...mainNav.button, linkType: mainNav.button.type });
    if (mainNavButton) mainNavigation.button = mainNavButton;
  }

  const contactLinks = buildContactLinks({
    phoneNumber: contactPhoneNumber,
    emailAddress: contactEmailAddress,
    messengerAccount: contactMessengerAccount,
    whatsAppNumber: contactWhatsAppNumber,
  });

  const legalLinks = buildLegalLinks({ legalNavigation });
  const footerLogos = buildFooterLogos({ siteLogo, affiliateLogoImage, affiliateLogoUrl, affiliateLogoImageAltText });
  const footerMenuGroups = buildMenuGroups({
    menuGroups: [
      ...footerNavigation.productGroups.map(
        (productGroup: ProductGroup): MenuGroup => ({
          key: productGroup.key,
          name: productGroup.name,
          menuGroupUrl: productGroup.url,
          menuLinks: productGroup.products.map(
            (product: Product): MenuLink => ({
              key: product.key,
              name: product.name,
              url: product.url,
            }),
          ),
        }),
      ),
      ...footerNavigation.menuGroups,
    ],
  });

  const socialLinks = buildSocialLinks({ socialMediaAccounts });

  const globalProps: GlobalProps = {
    contactLinks,
    defaultSeo: {
      openGraph: {
        type: openGraphType,
        locale: openGraphLocale,
        url: openGraphUrl,
        siteName: openGraphSitename,
        images: openGraphImages.map((image: any) => ({
          url: `${process.env.MEDIA_URL}${image.url}`,
          width: image.width,
          height: image.height,
          alt: image.name,
          type: `image/${image.extension}`,
        })),
      },
      twitter: {
        handle: twitterHandle,
        site: twitterSite,
        cardType: twitterCardType,
      },
    },
    footer: {
      contactLinks,
      companyName,
      legalLinks,
      logos: footerLogos,
      menuGroups: footerMenuGroups,
      socialLinks,
    },
    mainNavigation,
    siteLogo,
    siteName: companyName,
    livechatId,
    redirects,
  };

  const trackingConfig = buildTrackingConfig(data.content);
  if (trackingConfig) globalProps.trackingConfig = trackingConfig;

  return { globalProps };
};

export default getInitialProps;
