import NextImage from 'next/image';
import cn from 'classnames';

import { Icon } from '@components/Icon';

import classes from './ProductIcon.classes';

export interface ProductIconProps {
  icon: string;
  name: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  theme: string;
}

function ProductIcon({ icon, theme, name, size }: ProductIconProps): JSX.Element {
  let dimensions;
  switch (size) {
    case 'xs':
      dimensions = 'w-5 h-5 text-[0.5em]';
      break;
    case 'sm':
      dimensions = 'w-6 h-6 text-[0.60em]';
      break;
    case 'md':
      dimensions = 'w-8 h-8 text-[1em]';
      break;
    case 'lg':
      dimensions = 'w-10 h-10 text-[1.2em]';
      break;
    case 'xl':
      dimensions = 'w-12 h-12 text-[1.4em]';
      break;
    default:
      dimensions = 'w-8 h-8 text-[0.9em]';
  }

  return (
    <div className={cn(dimensions, classes.container)}>
      <NextImage
        src={`/assets/click-hexagon-${theme?.toLowerCase() || 'orange'}.svg`}
        alt={name}
        width={80}
        height={80}
        sizes="100vw"
        quality={100}
        className={classes.hexagon}
      />
      <Icon
        icon={icon || 'fak fa-click-dealer'}
        className={classes.icon}
        span="inline-flex justify-center items-center"
      />
    </div>
  );
}

export default ProductIcon;
