import { SocialLink } from '@interfaces';
import { Icon } from '@components/Icon';

import classes from './SocialLinks.classes';

export interface SocialLinksProps {
  socialLinks: SocialLink[];
  parent?: string;
}

function SocialLinks({ socialLinks, parent }: SocialLinksProps): JSX.Element {
  const blockIdentifier = parent ? `SocialLinks[${parent}]` : 'SocialLinks';

  return (
    <ul className={classes.ul}>
      {socialLinks.map((socialLink: SocialLink) => (
        <li key={socialLink.url} className={classes.li}>
          <a
            href={socialLink.url}
            target="_blank"
            rel="noreferrer"
            data-element-click={`link[${socialLink.name}]_${blockIdentifier}`}
            aria-label={`${socialLink.name} (opens in a new tab)`}
            className={classes.link}
          >
            {socialLink.icon && <Icon icon={socialLink.icon} className={classes.icon} aria-hidden="true" />}
            <span className={socialLink.icon ? 'sr-only' : undefined}>{socialLink.name}</span>
          </a>
        </li>
      ))}
    </ul>
  );
}

export default SocialLinks;
