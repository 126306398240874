import { ClassesBuilder } from '@utilities';

const classesConfig = {
  header: {
    default: 'z-50 fixed top-0 left-0 right-0 py-6 px-6',
    md: 'md:py-8 md:px-8',
  },
  headerScrolled: 'bg-white shadow-md py-4 px-4 md:py-4 md:px-4',
  headerScrolling: 'transition-all duration-300 ease-in-out',
  container: 'container flex items-center justify-between gap-4 m-auto align-bottom',
  logoContainer: {
    default: 'relative h-8',
    '2xs': '2xs:h-10',
    sm: 'sm:h-10',
    md: 'md:h-12 md:mr-8',
  },
  imageContainer: 'relative h-full',
  image: 'w-auto h-full',
  mainNavigationContainer: {
    lg: 'lg:flex-auto',
  },
  telephoneNumber:
    'text-lg ml-4 font-semibold px-4 py-2 rounded-md whitespace-nowrap transition hover:scale-105 active:scale-95 bg-click-orange hover:bg-click-blue text-white',
  telephoneIcon: 'mr-2 text-white',
};

export default new ClassesBuilder(classesConfig);
