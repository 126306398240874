import { ContactLink } from '@interfaces';

function buildContactLinks(props: any): ContactLink[] {
  const links: ContactLink[] = [];

  if (props?.phoneNumber) {
    links.push({
      type: 'phone',
      icon: 'fas fa-phone text-click-orange',
      name: props.phoneNumber,
      url: `tel:${props.phoneNumber.replaceAll(' ', '')}`,
    });
  }

  if (props?.emailAddress) {
    links.push({
      type: 'email',
      icon: 'fas fa-envelope text-click-orange',
      name: props.emailAddress,
      url: `mailto:${props.emailAddress}`,
    });
  }

  if (props?.messengerAccount) {
    links.push({
      type: 'messenger',
      icon: 'fab fa-facebook-messenger text-messenger',
      name: 'Messenger',
      url: `https://m.me/${props.messengerAccount}`,
    });
  }

  if (props?.whatsAppNumber) {
    links.push({
      type: 'whatsapp',
      icon: 'fak fa-whatsapp-solid text-whatsapp',
      name: 'WhatsApp',
      url: `https://wa.me/${props.whatsAppNumber}`,
    });
  }

  return links;
}

export default buildContactLinks;
