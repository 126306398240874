import { ClassesBuilder } from '@utilities';

const classesConfig = {
  ul: {
    default: 'grid grid-cols-1',
    '2xs': '2xs:grid-cols-2',
    md: 'md:grid-cols-3',
    lg: 'lg:grid-cols-1',
  },
  link: 'flex items-center p-2 transition duration-300 bg-transparent group hover:bg-click-grey hover:rounded-md',
  iconContainer: 'mr-1',
  nameContainer: 'flex-1',
  name: {
    default: 'text-sm font-semibold text-click-blue',
    sm: 'sm:text-base',
  },
  strapline: {
    default: 'hidden text-xs leading-none text-gray-500 hover:text-click-blue',
    xs: 'xs:block',
  },
};

export default new ClassesBuilder(classesConfig);
