import { ClassesBuilder } from '@utilities';

const classesConfig = {
  nav: {
    default: 'z-10 absolute top-4 right-4 left-4 bg-white rounded-lg shadow-nav p-6',
    sm: 'sm:p-8',
    md: 'md:mx-auto',
    lg: 'lg:block lg:static lg:top-0 lg:right-0 lg:left-0 lg:bg-transparent lg:rounded-none lg:shadow-none lg:p-0',
  },
  navHidden: 'hidden',
  ul: {
    lg: 'lg:flex lg:justify-end lg:gap-2',
  },
  buttonContainer: {
    default: '',
    lg: 'lg:inline-block lg:ml-12',
    xl: 'xl:ml-24',
  },
  productsGroupListItem: 'group',
  menuGroupListItem: {
    default: 'py-4 border-b border-dashed border-grey-500 group cursor-pointer last:border-none',
    lg: 'lg:relative lg:py-0 lg:border-none',
  },
  menuButton: {
    default: `flex items-center px-3 py-2 text-sm font-semibold leading-none text-white transition rounded-md bg-click-blue whitespace-nowrap hover:bg-click-orange line`,
    lg: 'lg:hidden',
  },
  menuButtonIcon: 'ml-2',
  closeButton: {
    default: `absolute w-8 h-8 flex justify-center items-center transition bg-gray-100 rounded-full top-3 right-3 text-click-blue hover:text-white hover:bg-click-blue hover:scale-105`,
    sm: 'sm:top-4 sm:right-4',
    lg: 'lg:hidden',
  },
  telephoneNumber: 'mt-4',
};

export default new ClassesBuilder(classesConfig);
