import { ClassesBuilder } from '@utilities';

const classesConfig = {
  ul: {
    default: 'z-20 mx-auto',
    lg: 'lg:absolute lg:top-20 lg:right-8 lg:left-8 lg:hidden lg:group-hover:grid lg:grid-cols-3 lg:rounded-xl lg:shadow-xl lg:bg-white lg:p-8 lg:max-w-screen-lg',
  },
  li: {
    default: 'pb-3 mb-3 border-b border-dashed border-grey-500',
    lg: 'lg:pb-0 lg:border-none',
  },
  heading: 'block my-2 text-xs font-semibold uppercase text-click-text',
  viewAllContainer: {
    default: 'col-span-3 py-4 border-dashed border-grey-500',
    lg: 'lg:py-0 lg:pt-4 lg:mt-8 lg:border-t',
  },
  viewAllLink: {
    default:
      'block p-4 text-sm font-bold text-center transition rounded-md text-click-blue hover:text-white bg-click-grey hover:bg-click-blue',
    lg: 'lg:text-left lg:p-0 lg:hover:text-click-orange lg:bg-transparent lg:hover:bg-transparent',
  },
  viewAllIcon: 'ml-2',
};

export default new ClassesBuilder(classesConfig);
