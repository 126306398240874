import { RichTextBlock } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildTheme from './buildTheme';
import buildImage from './buildImage';
import buildLink from './buildLink';

function buildRichTextBlock(props: any): RichTextBlock | null {
  if (!props?.richText.trim()) return null;

  const richTextBlock: RichTextBlock = {
    blockIdentifier: props.title ? `RichTextBlock[${props.title}]` : 'RichTextBlock',
    richText: props.richText.trim(),
  };

  if (props.caption) richTextBlock.caption = props.caption;
  if (props.captionImage) richTextBlock.captionImage = buildImage(props.captionImage, props.altText);
  if (props.title) richTextBlock.title = props.title;
  if (props.customID) richTextBlock.customID = getCustomId(props.customID);
  if (props.fillBackground) richTextBlock.fillBackground = props.fillBackground;

  const button = buildButton(props.button);
  if (button) richTextBlock.button = button;

  const link = buildLink(props.link);
  if (link) richTextBlock.link = link;

  if (props.blockTheme) richTextBlock.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return richTextBlock;
}

export default buildRichTextBlock;
