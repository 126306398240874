import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { MainNavigation as MainNavigationInterface, MenuGroup as MenuGroupInterface } from '@interfaces';
import { Button, ButtonProps } from '@components/Button';
import { Icon } from '@components/Icon';
import { Link } from '@components/Link';
import { useDesktopSize } from '@utilities';

import { ProductsMenuGroup } from './ProductsMenuGroup';
import { MenuGroup } from './MenuGroup';

import classes from './MainNavigation.classes';

export interface MainNavigationProps extends MainNavigationInterface {}

function MainNavigation({
  productsMenuGroup,
  menuGroups,
  button,
  telephoneNumber,
  telephoneUrl,
}: MainNavigationProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const isDesktop = useDesktopSize();

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setIsOpen(false);
    });
  }, [router]);

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_MainNavigation`,
      children: button.name,
    };
  }

  return (
    <>
      <nav className={cn(classes.nav, { [classes.navHidden]: !isOpen })}>
        <button
          type="button"
          className={classes.closeButton}
          data-element-click="button[closeMenu]_MainNavigation"
          onClick={() => setIsOpen(false)}
        >
          <Icon icon="fas fa-xmark" />
        </button>
        <ul className={classes.ul}>
          {productsMenuGroup ? (
            <li className={classes.productsGroupListItem}>
              <ProductsMenuGroup {...productsMenuGroup} />
            </li>
          ) : null}
          {menuGroups?.length
            ? menuGroups.map((menuGroup: MenuGroupInterface) => (
                <li key={menuGroup.key} className={classes.menuGroupListItem}>
                  <MenuGroup
                    name={menuGroup.name}
                    menuGroupUrl={menuGroup.menuGroupUrl}
                    menuLinks={menuGroup.menuLinks}
                  />
                </li>
              ))
            : null}
          {!isDesktop && telephoneNumber && (
            <div className={classes.telephoneNumber}>
              <Link href={telephoneUrl || '/contact'}>{telephoneNumber}</Link>
            </div>
          )}
          {buttonProps && (
            <li className={classes.buttonContainer}>
              <Button {...buttonProps} />
            </li>
          )}
        </ul>
      </nav>
      <button
        className={classes.menuButton}
        type="button"
        data-element-click="button[openMenu]_MainNavigation"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Menu</span>
        <Icon icon="fas fa-bars" className={classes.menuButtonIcon} />
      </button>
    </>
  );
}

export default MainNavigation;
