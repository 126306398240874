import { TrackingConfig } from '@interfaces';

function buildTrackingConfig({
  googleTagManagerContainerID,
  googleAnalyticsMeasurementID,
}: any): TrackingConfig | null {
  if (!googleTagManagerContainerID) return null;

  const trackingConfig: TrackingConfig = { googleTagManagerContainerID };

  if (googleAnalyticsMeasurementID) trackingConfig.googleAnalyticsMeasurementID = googleAnalyticsMeasurementID;

  return trackingConfig;
}

export default buildTrackingConfig;
