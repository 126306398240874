import { Link } from '@interfaces';

function buildButton(props: any): Link | undefined {
  if (!props?.url) return undefined;

  return {
    href: props.url,
    name: props.name,
    target: props.target,
    linkType: props.linkType.toLowerCase(),
  };
}

export default buildButton;
