import { init as initApm } from '@elastic/apm-rum';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { GoogleTagManager } from '@next/third-parties/google';

import { PreviewNotification } from '@components/PreviewNotification';
import { Layout } from '@components/Layout';
import { getInitialProps } from '@backend';
import { GlobalProps } from '@interfaces';
import { GlobalData } from '@contexts';

import '../styles/globals.css';
import { CookieBanner } from '@components/CookieBanner';
import getGtag from '@utilities/getGtag';
import Script from 'next/script';

interface CustomAppProps extends AppProps {
  globalProps: GlobalProps;
}

function MyApp({ Component, pageProps, globalProps }: CustomAppProps): JSX.Element {
  initApm({
    serviceName: 'click-dealer-website',
    serverUrl: process.env.ELASTIC_APM_SERVER_URL,
    environment: process.env.ELASTIC_APM_ENVIRONMENT,
  });
  const gtag = getGtag(globalProps.trackingConfig?.googleAnalyticsMeasurementID!);
  return (
    <GlobalData.Provider value={globalProps}>
      {globalProps.trackingConfig && (
        <GoogleTagManager gtmId={globalProps.trackingConfig.googleTagManagerContainerID} />
      )}
      {gtag}
      {globalProps.defaultSeo && (
        <DefaultSeo
          {...globalProps.defaultSeo}
          dangerouslySetAllPagesToNoFollow={process.env.ENVIRONMENT_NAME !== 'production'}
          dangerouslySetAllPagesToNoIndex={process.env.ENVIRONMENT_NAME !== 'production'}
        />
      )}
      <Layout globalProps={globalProps}>
        <Component globalProps={globalProps} {...pageProps} />
      </Layout>

      {process.env.ENVIRONMENT_NAME !== 'production' && <Script src={globalProps.livechatId} strategy="lazyOnload" />}
      {pageProps?.isPreviewMode && <PreviewNotification />}
      <CookieBanner />
    </GlobalData.Provider>
  );
}

MyApp.getInitialProps = async () => ({ ...(await getInitialProps()) });

export default MyApp;
