import { PropsWithChildren } from 'react';
import NextLink from 'next/link';
import cn from 'classnames';

import { useTheme } from '@utilities';
import { Theme } from '@interfaces';
import { LinkType } from '@types';

import classes from './Button.classes';

export interface ButtonProps extends PropsWithChildren {
  href?: string;
  linkType?: LinkType;
  target?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
  elementClick?: string;
  onClick?: () => any;
  blockTheme?: Theme;
  style?: 'rounded' | 'standard';
  size?: 'small' | 'medium';
}
function Button({
  href,
  linkType,
  target,
  type,
  disabled,
  elementClick,
  onClick,
  children,
  blockTheme,
  style = 'standard',
  size = 'medium',
}: ButtonProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let button = (
    <button
      className={cn(
        classes.button,
        theme.background,
        theme.backgroundHover,
        { [classes.roundedButton]: style === 'rounded' },
        { [classes.smallButton]: size === 'small' },
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      data-element-click={elementClick || null}
    >
      {children}
    </button>
  );

  if (href && linkType === 'content') {
    button = <NextLink href={href}>{button}</NextLink>;
  }

  if (href && linkType !== 'content') {
    button = (
      <a href={href} target={target || '_self'} rel="noreferrer">
        {button}
      </a>
    );
  }

  return button;
}

export default Button;
