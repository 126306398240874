import { Link } from '@components/Link';
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { getCookie, setCookie } from 'cookies-next';

import classes from './CookieBanner.classes';

function CookieBanner(): JSX.Element | null {
  const [showCookiePolicy, setShowCookiePolicy] = useState(true);
  const { register, handleSubmit } = useForm();

  const onSubmit = () => {
    try {
      const COOKIE_EXPIRE = new Date();
      COOKIE_EXPIRE.setFullYear(COOKIE_EXPIRE.getFullYear() + 1);
      setCookie('hideCookiePolicy', 'true', { expires: COOKIE_EXPIRE, path: '/' });
      setCookie('accepted_cookies', 'true', { expires: COOKIE_EXPIRE, path: '/' });
      setShowCookiePolicy(false);
    } catch (error) {
      console.error('Error setting cookies:', error);
    }
  };

  useEffect(() => {
    setShowCookiePolicy(getCookie('hideCookiePolicy') !== 'true');
  }, []);

  return showCookiePolicy ? (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.cookieBanner}>
          <p className={classes.bannerText}>
            We use cookies to help our site function correctly and to understand how it is used. By clicking &quot;I
            understand&quot;, you agree to us doing so. You can find out more about cookies and how to manage them in
            our <Link href="/cookie-policy">Cookie Policy.</Link>
          </p>
          <button type="submit" {...register('acceptCookies')} className={classes.acceptButton}>
            I understand
          </button>
        </div>
      </form>
    </div>
  ) : null;
}

export default CookieBanner;
