import NextLink from 'next/link';

import { hideMainNavigationMenuGroups } from '@utilities';
import { ProductIcon } from '@components/ProductIcon';
import { Product } from '@interfaces';

import classes from './ProductsList.classes';

export interface ProductsListProps {
  products: Product[];
}

function ProductsList({ products }: ProductsListProps): JSX.Element {
  return (
    <ul className={classes.ul}>
      {products.map((product: Product) => {
        let productName = <span>{product.name}</span>;

        const nameParts = product.name.split(' ');
        const firstPart = nameParts.shift();

        if (firstPart === 'Click') {
          let className;

          switch (product.theme) {
            case 'Manage':
              className = 'text-click-manage';
              break;
            case 'Digital':
              className = 'text-click-digital';
              break;
            case 'Marketing':
              className = 'text-click-marketing';
              break;
            default:
              className = 'text-click-orange';
          }

          productName = (
            <>
              <span>{firstPart}</span>
              <span className={className}>{nameParts.join('')}</span>
            </>
          );
        }

        return (
          <li key={product.key}>
            <NextLink
              href={product.url}
              className={classes.link}
              data-element-click={`link[${product.name}]_ProductsList[${product.theme}]`}
              onClick={() => hideMainNavigationMenuGroups()}
            >
              <div className={classes.iconContainer}>
                <ProductIcon icon={product.icon} name={product.name} size="md" theme={product.theme} />
              </div>
              <div className={classes.nameContainer}>
                <div className={classes.name}>{productName}</div>
                <div className={classes.strapline}>{product.strapline}</div>
              </div>
            </NextLink>
          </li>
        );
      })}
    </ul>
  );
}

export default ProductsList;
