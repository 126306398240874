import { Map } from '@interfaces';
import { getCustomId } from '@utilities';

function buildMap(props: any): Map | null {
  if (!props.googleMapsLink) return null;

  const map: Map = {
    mapSrc: props.googleMapsLink,
  };

  if (props.customID) map.customID = getCustomId(props.customID);

  return map;
}

export default buildMap;
