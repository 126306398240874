import { Address } from '@interfaces';
import { getCustomId } from '@utilities';

import buildImage from './buildImage';
import buildTheme from './buildTheme';
import buildButton from './buildButton';
import buildLink from './buildLink';

function buildAddress(props: any): Address | null {
  if (!props?.line1 || !props?.town || !props?.postcode) return null;

  const address: Address = {
    blockIdentifier: props.title ? `Address[${props.title}]` : 'Address',
    line1: props.line1,
    town: props.town,
    postcode: props.postcode,
  };

  if (props.caption) address.caption = props.caption;
  if (props.captionImage) address.captionImage = buildImage(props.captionImage, props.altText);
  if (props.title) address.title = props.title;
  if (props.companyName) address.companyName = props.companyName;
  if (props.line2) address.line2 = props.line2;
  if (props.county) address.county = props.county;

  const button = buildButton(props.button);
  if (button) address.button = button;

  const link = buildLink(props.link);
  if (link) address.link = link;

  if (props.customID) address.customID = getCustomId(props.customID);

  if (props.blockTheme) address.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return address;
}

export default buildAddress;
