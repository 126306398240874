import { VideoWithContent } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildTheme from './buildTheme';
import buildVideo from './buildVideo';
import buildImage from './buildImage';
import buildLink from './buildLink';

function buildVideoWithContent(props: any): VideoWithContent | undefined {
  const video = buildVideo(props);

  if (!props?.title || !video) return undefined;

  const videoWithContent: VideoWithContent = {
    blockIdentifier: `VideoWithContent[${props.title}]`,
    title: props.title,
    videoPositionDesktop: (props.videoPositionDesktop || 'right').toLowerCase(),
    videoPositionMobile: (props.videoPositionMobile || 'bottom').toLowerCase(),
    video,
  };

  if (props.caption) videoWithContent.caption = props.caption;
  if (props.captionImage) videoWithContent.captionImage = buildImage(props.captionImage, props.altText);
  if (props.richText) videoWithContent.richText = props.richText;

  const button = buildButton(props.button);
  if (button) videoWithContent.button = button;

  const link = buildLink(props.link);
  if (link) videoWithContent.link = link;

  if (props.blockTheme) videoWithContent.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  if (props.customID) videoWithContent.customID = getCustomId(props.customID);

  return videoWithContent;
}

export default buildVideoWithContent;
