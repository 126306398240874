import { Page } from '@interfaces';

import buildPageSections from './builders/buildPageSections';
import buildHeroUnit from './builders/buildHeroUnit';
import buildSeoProps from './builders/buildSeoProps';
import buildTheme from './builders/buildTheme';

function replaceUndefinedWithNull(obj: any): any {
  if (obj === undefined) return null;
  if (typeof obj !== 'object' || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj.map(replaceUndefinedWithNull);
  }

  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, replaceUndefinedWithNull(value)]));
}

const getStaticProps = async (path: string, preview: boolean | undefined) => {
  const result = await fetch(`${process.env.API_URL}${path}`);
  const data = await result.json();

  const heroUnit = buildHeroUnit(data.content);
  const sections = await Promise.all(await buildPageSections({ sections: data.content?.contentGrid?.blocks }));
  const seoProps = buildSeoProps({ ...data.content, pageName: data.metadata.name });
  const theme = buildTheme(data.content.pageTheme?.label?.toLowerCase());

  const page: Page = { sections, theme, isPreviewMode: !!preview };

  if (heroUnit) page.heroUnit = heroUnit;
  if (seoProps) page.seoProps = seoProps;

  // Replace all undefined values with null
  const serializedPage = replaceUndefinedWithNull(page);

  return { props: serializedPage };
};

export default getStaticProps;
