import { BlogPostPage, BlogPostSidebar } from '@interfaces';

import buildBlogPostHeroUnit from './builders/buildBlogPostHeroUnit';
import buildPageSections from './builders/buildPageSections';
import buildSeoProps from './builders/buildSeoProps';
import buildTheme from './builders/buildTheme';
import getBlogCategories from './getBlogCategories';
import getBlogTags from './getBlogTags';
import getBlogPosts, { GetBlogPostsProps } from './getBlogPosts';
import buildImage from './builders/buildImage';

const getBlogPostStaticProps = async (path: string, preview: boolean | undefined) => {
  const blogCategories = await getBlogCategories();
  const blogTags = await getBlogTags();
  const result = await fetch(`${process.env.API_URL}${path}`);
  const data = await result.json();
  const getBlogPostsProps: GetBlogPostsProps = { pageSize: 3 };
  getBlogPostsProps.category = '';
  const blogPosts = await getBlogPosts(getBlogPostsProps);
  const blockTheme = buildTheme(data.content.pageTheme?.label?.toLowerCase());
  const blogPostSidebar: BlogPostSidebar = {
    categories: blogCategories.map((category: any) => ({
      name: category.name,
      url: `/blog/category/${category.name.replaceAll(' ', '-').toLowerCase()}`,
      key: category.key,
      totalPosts: category.totalPosts,
    })),
    tags: blogTags.map((tag: any) => ({
      name: tag.name,
      url: `/blog/tag/${tag.name.replaceAll(' ', '-').toLowerCase()}`,
      key: tag.key,
      totalPosts: tag.totalPosts,
    })),
    latestPosts: blogPosts.items.map((blogPost: any) => ({
      key: blogPost.key,
      heading: blogPost.heading,
      article: blogPost.article ? blogPost.article : '',
      date: blogPost.date,
      author: blogPost.author,
      image: buildImage(blogPost.image),
      url: blogPost.url,
    })),
    theme: blockTheme,
  };
  const blogPostTags = data.content.tag;
  const blogPostCategories = data.content.categories[0].name;
  const blogPostHeroUnit = buildBlogPostHeroUnit(data.content);
  const sections = await Promise.all(await buildPageSections({ sections: data.content?.contentGrid?.blocks }));
  const seoProps = buildSeoProps({ ...data.content, pageName: data.metadata.name });

  const page: BlogPostPage = {
    article: data.content.article,
    blogPostHeroUnit,
    sections,
    theme: blockTheme,
    isPreviewMode: !!preview,
    sidebar: blogPostSidebar,
    blogCategory: blogPostCategories,
    blogTags: blogPostTags,
  };
  if (seoProps) page.seoProps = seoProps;

  return { props: { ...page } };
};

export default getBlogPostStaticProps;
