import { v4 as uuidv4 } from 'uuid';

import { Block } from '@interfaces';

import buildTwoColumnRichTextBlock from './buildTwoColumnRichTextBlock';
import buildBlogPostListByCategory from './buildBlogPostListByCategory';
import buildImageWithContent from './buildImageWithContent';
import buildSteppedPromotion from './buildSteppedPromotion';
import buildVideoWithContent from './buildVideoWithContent';
import buildFeaturedProducts from './buildFeaturedProducts';
import buildBlogCategoryList from './buildBlogCategoryList';
import buildBlogPostHeroUnit from './buildBlogPostHeroUnit';
import buildFeaturedReview from './buildFeaturedReview';
import buildImagePromotion from './buildImagePromotion';
import buildIconPromotion from './buildIconPromotion';
import buildRichTextBlock from './buildRichTextBlock';
import buildBlogPostList from './buildBlogPostList';
import buildBlogPostGrid from './buildBlogPostGrid';
import buildLabelledList from './buildLabelledList';
import buildVacancyList from './buildVacancyList';
import buildBlogTagList from './buildBlogTagList';
import buildReviewList from './buildReviewList';
import buildPromotion from './buildPromotion';
import buildAccordion from './buildAccordion';
import buildImageOnly from './buildImageOnly';
import buildBioList from './buildBioList';
import buildAddress from './buildAddress';
import buildForm from './forms/buildForm';
import buildReview from './buildReview';
import buildMap from './buildMap';
import buildComparisonList from './buildComparisonList';
import buildCaseStudiesList from './buildCaseStudiesList';

async function buildBlock({ name, props }: any): Promise<Block | null> {
  if (!name) return null;

  const key = uuidv4();

  if (name === 'Accordion') {
    const accordionProps = buildAccordion(props);
    if (accordionProps) return { key, name, props: accordionProps };
  }

  if (name === 'Address') {
    const addressProps = buildAddress(props);
    if (addressProps) return { key, name, props: addressProps };
  }

  if (name === 'BioList') {
    const bioListProps = buildBioList(props);
    if (bioListProps) return { key, name, props: bioListProps };
  }

  if (name === 'BlogCategoryList') {
    const blogCategoryListProps = await buildBlogCategoryList(props);
    if (blogCategoryListProps) return { key, name, props: blogCategoryListProps };
  }

  if (name === 'BlogPostListByCategory') {
    const blogPostListByCategoryProps = await buildBlogPostListByCategory(props);
    if (blogPostListByCategoryProps) return { key, name, props: blogPostListByCategoryProps };
  }

  if (name === 'BlogPostHeroUnit') {
    const blogPostHeroUnitProps = buildBlogPostHeroUnit(props);
    if (blogPostHeroUnitProps) return { key, name, props: blogPostHeroUnitProps };
  }

  if (name === 'BlogPostGrid') {
    const blogPostGridProps = await buildBlogPostGrid(props);
    if (blogPostGridProps) return { key, name, props: blogPostGridProps };
  }

  if (name === 'BlogPostList') {
    const blogPostListProps = await buildBlogPostList(props);
    if (blogPostListProps) return { key, name, props: blogPostListProps };
  }

  if (name === 'BlogTagList') {
    const blogTagListProps = await buildBlogTagList(props);
    if (blogTagListProps) return { key, name, props: blogTagListProps };
  }

  if (name === 'CaseStudiesList') {
    const caseStudiesListProps = await buildCaseStudiesList(props);
    if (caseStudiesListProps) return { key, name, props: caseStudiesListProps };
  }

  if (name === 'ComparisonList') {
    const comparisonListProps = buildComparisonList(props);
    if (comparisonListProps) return { key, name, props: comparisonListProps };
  }

  if (name === 'ContactDetails') {
    return { key, name };
  }

  if (name === 'FeaturedProducts') {
    const featuredProductsProps = buildFeaturedProducts(props);
    if (featuredProductsProps) return { key, name, props: featuredProductsProps };
  }

  if (name === 'FeaturedReview') {
    const featuredReviewProps = buildFeaturedReview(props);
    if (featuredReviewProps) return { key, name, props: featuredReviewProps };
  }

  if (name === 'Form') {
    const formProps = await buildForm(props);
    if (formProps) return { key, name, props: formProps };
  }

  if (name === 'ImageWithContent') {
    const imageWithContentProps = buildImageWithContent(props);
    if (imageWithContentProps) return { key, name, props: imageWithContentProps };
  }

  if (name === 'ImageOnly') {
    const imageOnlyProps = buildImageOnly(props);
    if (imageOnlyProps) return { key, name, props: imageOnlyProps };
  }

  if (name === 'IconPromotion') {
    const iconPromotionProps = buildIconPromotion(props);
    if (iconPromotionProps) return { key, name, props: iconPromotionProps };
  }

  if (name === 'ImagePromotion') {
    const imagePromotionProps = buildImagePromotion(props);
    if (imagePromotionProps) return { key, name, props: imagePromotionProps };
  }

  if (name === 'LabelledList') {
    const labelledListProps = buildLabelledList(props);
    if (labelledListProps) return { key, name, props: labelledListProps };
  }

  if (name === 'Map') {
    const mapProps = buildMap(props);
    if (mapProps) return { key, name, props: mapProps };
  }

  if (name === 'Promotion') {
    const promotionProps = buildPromotion(props);
    if (promotionProps) return { key, name, props: promotionProps };
  }

  if (name === 'Review') {
    const reviewProps = buildReview(props);
    if (reviewProps) return { key, name, props: reviewProps };
  }

  if (name === 'ReviewList') {
    const reviewListProps = await buildReviewList(props);
    if (reviewListProps) return { key, name, props: reviewListProps };
  }

  if (name === 'RichTextBlock') {
    const richTextProps = buildRichTextBlock(props);
    if (richTextProps) return { key, name, props: richTextProps };
  }

  if (name === 'SteppedPromotion') {
    const steppedPromotionProps = buildSteppedPromotion(props);
    if (steppedPromotionProps) return { key, name, props: steppedPromotionProps };
  }

  if (name === 'TwoColumnRichTextBlock') {
    const twoColumnRichTextProps = buildTwoColumnRichTextBlock(props);
    if (twoColumnRichTextProps) return { key, name, props: twoColumnRichTextProps };
  }

  if (name === 'VacancyList') {
    const vacancyListProps = buildVacancyList(props);
    if (vacancyListProps) return { key, name, props: vacancyListProps };
  }

  if (name === 'VideoWithContent') {
    const videoWithContentProps = buildVideoWithContent(props);
    if (videoWithContentProps) return { key, name, props: videoWithContentProps };
  }

  return null;
}

export default buildBlock;
