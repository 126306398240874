import { useEffect, useState } from 'react';
import cn from 'classnames';

import classes from './Icon.classes';

export interface IconProps {
  icon: string;
  className?: string;
  span?: string;
}

function Icon({ icon, className, span }: IconProps): JSX.Element {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  let iconElement = null;

  if (!loading) {
    iconElement = <i className={cn(icon, classes.icon)} />;
  }

  return (
    <span className={className}>
      <span className={cn(classes.span, span || 'inline-block')} aria-hidden="true">
        {iconElement}
      </span>
    </span>
  );
}

export default Icon;
