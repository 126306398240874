import { useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import cn from 'classnames';

import { hideMainNavigationMenuGroups } from '@utilities';
import { ProductGroup } from '@interfaces';
import { Icon } from '@components/Icon';

import { ProductsList } from './ProductsList';

import classes from './ProductGroupsList.classes';

export interface ProductGroupsListProps {
  productGroups: ProductGroup[];
  viewAllLinkText?: string;
  viewAllUrl?: string;
}

function ProductGroupsList({ productGroups, viewAllLinkText, viewAllUrl }: ProductGroupsListProps): JSX.Element {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeScrolled = window.scrollY > 50;

      if (shouldBeScrolled !== isScrolled) {
        setIsScrolled(shouldBeScrolled);
        setIsScrolling(true);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setIsScrolling(false);
        }, 100);
      }
    };

    // Set initial scroll state without triggering transitions
    setIsScrolled(window.scrollY > 50);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isScrolled]);
  return (
    <ul className={cn('main-navigation-menu-group', classes.ul, isScrolled || isScrolling ? '-mt-4' : '')}>
      {productGroups.map((productGroup: ProductGroup) => (
        <li key={productGroup.key} className={classes.li}>
          <div className={classes.heading}>{productGroup.name}</div>
          <ProductsList products={productGroup.products} />
        </li>
      ))}
      {viewAllLinkText && viewAllUrl && (
        <li className={classes.viewAllContainer}>
          <NextLink
            href={viewAllUrl}
            className={classes.viewAllLink}
            data-element-click={`link[${viewAllLinkText}]_ProductGroupsNavigation`}
            onClick={() => hideMainNavigationMenuGroups()}
          >
            {viewAllLinkText}
            <Icon icon="fas fa-arrow-right" className={classes.viewAllIcon} />
          </NextLink>
        </li>
      )}
    </ul>
  );
}

export default ProductGroupsList;
