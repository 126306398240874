import { useContext } from 'react';

import { DarkBackground as DarkBackgroundContext, Theme as ThemeContext } from '@contexts';
import buildTheme from '@backend/builders/buildTheme';
import { Theme as ThemeInterface } from '@interfaces';

function useTheme({ blockTheme }: { blockTheme?: ThemeInterface | null }): ThemeInterface {
  const parentTheme = useContext(ThemeContext);
  const hasDarkBackground = useContext(DarkBackgroundContext);
  const theme = blockTheme || parentTheme || buildTheme();

  if (!hasDarkBackground) return theme;

  const darkTheme = { ...theme };
  darkTheme.titleText = 'text-white';
  darkTheme.bodyText = 'text-white text-opacity-80';

  switch (darkTheme.name) {
    case 'manage':
      darkTheme.backgroundHover = 'hover:bg-click-manage-light';
      darkTheme.colorHover = 'hover:text-click-manage-light';
      break;
    case 'marketing':
      darkTheme.backgroundHover = 'hover:bg-click-marketing-light';
      darkTheme.colorHover = 'hover:text-click-marketing-light';
      break;
    case 'digital':
      darkTheme.backgroundHover = 'hover:bg-click-digital-light';
      darkTheme.colorHover = 'hover:text-click-digital-light';
      break;
    default:
      darkTheme.backgroundHover = 'hover:bg-click-orange-light';
      darkTheme.colorHover = 'hover:text-click-orange-light';
      break;
  }

  return darkTheme;
}

export default useTheme;
