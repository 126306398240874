import { ClassesBuilder } from '@utilities';

const classesConfig = {
  horizontal: {
    default: 'flex flex-wrap gap-4 gap-y-2',
    sm: 'sm:gap-x-4',
    md: 'md:gap-x-8',
  },
  vertical: {
    default: 'flex flex-col gap-4 gap-y-2',
    sm: 'sm:gap-x-4',
    md: 'md:gap-x-8',
  },
  li: 'flex items-center',
  link: 'font-semibold transition text-md text-click-blue hover:text-click-orange',
  icon: 'mr-2',
};

export default new ClassesBuilder(classesConfig);
