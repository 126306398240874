import { PropsWithChildren } from 'react';
import NextLink from 'next/link';
import cn from 'classnames';

import { Icon } from '@components/Icon';
import { useTheme } from '@utilities';
import { Theme } from '@interfaces';
import { LinkType } from '@types';

import classes from './Link.classes';

export interface LinkProps extends PropsWithChildren {
  href: string;
  linkType?: LinkType;
  target?: string;
  icon?: string;
  blockTheme?: Theme;
  elementClick?: string;
  onClick?: () => void | undefined;
}

function Link({
  href,
  linkType = 'content',
  target,
  icon,
  children,
  blockTheme,
  elementClick,
  onClick,
}: LinkProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let link = (
    <span>
      {children}
      {icon && <Icon icon={icon} className="ml-2" />}
    </span>
  );

  if (linkType !== 'content') {
    link = (
      <a
        href={href}
        target={target || '_self'}
        rel="noreferrer"
        className={cn(classes.link, theme.color, theme.colorHover)}
        onClick={onClick}
        data-element-click={elementClick || null}
      >
        {link}
      </a>
    );
  }

  if (linkType === 'content') {
    link = (
      <NextLink
        href={href}
        className={cn(classes.link, theme.color, theme.colorHover)}
        data-element-click={elementClick || null}
        onClick={onClick}
      >
        {link}
      </NextLink>
    );
  }

  return link;
}

export default Link;
