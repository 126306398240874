function hideMainNavigationMenuGroups() {
  const menuGroupSelector = '.main-navigation-menu-group';
  const menuGroupToggleClassName = 'lg:group-hover:grid';

  const menuGroups = document.querySelectorAll(menuGroupSelector);
  menuGroups.forEach((menuGroup) => menuGroup.classList.remove(menuGroupToggleClassName));
  setTimeout(() => {
    menuGroups.forEach((menuGroup) => menuGroup.classList.add(menuGroupToggleClassName));
  }, 100);
}

export default hideMainNavigationMenuGroups;
