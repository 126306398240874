import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default: 'flex flex-col items-start mb-8',
    sm: 'sm:flex sm:flex-row sm:justify-between sm:items-start',
    md: 'md:mb-10',
  },
  logoContainer: 'flex flex-col items-start',
  imageContainer: {
    default: 'h-8 mb-4',
    '2xs': '2xs:h-10',
    sm: 'sm:mb-0',
  },
  image: 'w-auto h-full',
};

export default new ClassesBuilder(classesConfig);
