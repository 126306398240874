import { v4 as uuidv4 } from 'uuid';

import { Accordion } from '@interfaces';
import { getCustomId } from '@utilities';

import buildTheme from './buildTheme';
import buildImage from './buildImage';
import buildButton from './buildButton';
import buildLink from './buildLink';

function buildAccordion(props: any): Accordion | null {
  if (!props?.title || !props?.items || props.items.length < 1) return null;

  const items = props.items.map((item: any) => ({
    key: uuidv4(),
    title: item.content.title,
    summary: item.content.summary,
  }));

  const accordion: Accordion = {
    blockIdentifier: `Accordion[${props.title}]`,
    items,
    title: props.title,
  };

  if (props.customID) accordion.customID = getCustomId(props.customID);
  if (props.blockTheme) accordion.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());
  if (props.caption) accordion.caption = props.caption;
  if (props.captionImage) accordion.captionImage = buildImage(props.captionImage, props.altText);

  const button = buildButton(props.button);
  if (button) accordion.button = button;

  const link = buildLink(props.link);
  if (link) accordion.link = link;

  return accordion;
}

export default buildAccordion;
