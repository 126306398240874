import { PropsWithChildren } from 'react';
import { GlobalProps } from '@interfaces';

import { Header } from '../Header';
import { Footer } from '../Footer';

export interface LayoutProps extends PropsWithChildren {
  globalProps: GlobalProps;
}

function Layout({ globalProps, children }: LayoutProps): JSX.Element {
  return (
    <>
      <Header
        siteName={globalProps.siteName}
        siteLogo={globalProps.siteLogo}
        mainNavigation={globalProps.mainNavigation}
        telephoneNumber={globalProps.contactLinks[0].name}
        telephoneUrl={globalProps.contactLinks[0].url}
      />
      {children}
      <Footer {...globalProps.footer} />
    </>
  );
}

export default Layout;
