import { Image, ImageWithContent } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildImage from './buildImage';
import buildTheme from './buildTheme';
import buildLink from './buildLink';

function buildImageWithContent(props: any): ImageWithContent | undefined {
  if (!props?.title) return undefined;

  const imageWithContent: ImageWithContent = {
    blockIdentifier: `ImageWithContent[${props.title}]`,
    title: props.title,
    image: buildImage(props.image) as Image,
    imagePositionDesktop: (props.imagePositionDesktop || 'right').toLowerCase(),
    imagePositionMobile: (props.imagePositionMobile || 'bottom').toLowerCase(),
  };

  if (props.caption) imageWithContent.caption = props.caption;
  if (props.captionImage) imageWithContent.captionImage = buildImage(props.captionImage, props.altText);
  if (props.richText) imageWithContent.richText = props.richText;
  if (props.customID) imageWithContent.customID = getCustomId(props.customID);

  const button = buildButton(props.button);
  if (button) imageWithContent.button = button;

  const link = buildLink(props.link);
  if (link) imageWithContent.link = link;

  if (props.blockTheme) imageWithContent.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return imageWithContent;
}

export default buildImageWithContent;
