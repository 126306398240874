import { ClassesBuilder } from '@utilities';

const classesConfig = {
  ul: 'flex flex-wrap pt-2 space-x-4',
  li: 'flex items-center',
  icon: 'text-2xl transition text-social hover:text-click-blue',
  link: 'text-gray-600 hover:text-gray-800 transition-colors duration-200',
};

export default new ClassesBuilder(classesConfig);
