import { FeaturedReview } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildImage from './buildImage';
import buildLink from './buildLink';
import buildTheme from './buildTheme';

function buildFeaturedReview(props: any): FeaturedReview | null {
  if (!props?.reviewText) return null;

  const featuredReview: FeaturedReview = {
    blockIdentifier: `FeaturedReview[${props.title || props?.reviewText}]`,

    reviewText: props.reviewText,
    reviewRating: props.reviewRating,
    reviewerName: props.reviewerName,
    reviewerCaption: props.reviewerCaption,
  };

  if (props.title) featuredReview.title = props.title;
  if (props.caption) featuredReview.caption = props.caption;
  if (props.captionImage) featuredReview.captionImage = buildImage(props.captionImage, props.altText);
  if (props.customID) featuredReview.customID = getCustomId(props.customID);

  const image = buildImage(props.image);
  if (image) featuredReview.image = image;

  const link = buildLink(props.link);
  if (link) featuredReview.link = link;

  const button = buildButton(props.button);
  if (button) featuredReview.button = button;

  if (props.blockTheme) featuredReview.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return featuredReview;
}

export default buildFeaturedReview;
