/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { Block } from '@interfaces';

import buildBlock from './buildBlock';

async function buildBlockList(props: any): Promise<Block[]> {
  if (!props?.blocks) return [];

  const blocks: Block[] = [];

  for (const block of props.blocks) {
    const name = block.alias.charAt(0).toUpperCase() + block.alias.slice(1);
    const builtBlock = await buildBlock({ name, props: { ...block.content } });
    if (builtBlock) blocks.push(builtBlock);
  }

  return blocks;
}

export default buildBlockList;
