import NextLink from 'next/link';

import { MenuGroup, MenuLink } from '@interfaces';

import classes from './FooterMenuGroups.classes';

export interface FooterMenuGroupsProps {
  menuGroups?: MenuGroup[];
}

function FooterMenuGroups({ menuGroups }: FooterMenuGroupsProps): JSX.Element | null {
  if (!menuGroups?.length) return null;

  return (
    <nav className={classes.nav}>
      {menuGroups.map((menuGroup: MenuGroup) => (
        <div key={menuGroup.key}>
          <div className={classes.header}>
            <NextLink href={menuGroup.menuGroupUrl} data-element-click={`link[${menuGroup.name}]_FooterMenuGroups`}>
              {menuGroup.name}
            </NextLink>
          </div>
          <ul>
            {menuGroup.menuLinks.map((menuLink: MenuLink) => (
              <li key={menuLink.key} className={classes.li}>
                <NextLink
                  href={menuLink.url}
                  className={classes.link}
                  data-element-click={`link[${menuLink.name}]_FooterMenuGroups[${menuGroup.name}]`}
                >
                  {menuLink.name}
                </NextLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
}

export default FooterMenuGroups;
