const getBorder = (themeName: string) => {
  let border;
  switch (themeName) {
    case 'digital':
      border = `hover:border-click-digital`;
      break;
    case 'manage':
      border = `hover:border-click-manage`;
      break;
    case 'marketing':
      border = `hover:border-click-marketing`;
      break;
    default:
      border = `hover:border-click-orange`;
      break;
  }
  return border;
};

export default getBorder;
