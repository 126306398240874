import { Product, FeaturedProducts } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildTheme from './buildTheme';
import buildImage from './buildImage';
import buildLink from './buildLink';

function buildFeaturedProducts(props: any): FeaturedProducts | null {
  if (!props?.products || props.products.length < 1) return null;

  const featuredProducts: FeaturedProducts = {
    blockIdentifier: `FeaturedProducts[${props.title}]`,
    title: props.title,
    products: [],
  };

  if (props.caption) featuredProducts.caption = props.caption;
  if (props.captionImage) featuredProducts.captionImage = buildImage(props.captionImage, props.altText);
  if (props.summary) featuredProducts.summary = props.summary;
  if (props.customID) featuredProducts.customID = getCustomId(props.customID);

  const button = buildButton(props.button);
  if (button) featuredProducts.button = button;

  const link = buildLink(props.link);
  if (link) featuredProducts.link = link;

  const productList: Product[] = props.products.map((product: any) => ({
    key: product.key,
    name: product.name,
    strapline: product.strapline,
    icon: product.icon,
    url: product.url,
    theme: product.theme,
  }));

  if (productList) featuredProducts.products = productList;

  if (props.blockTheme) featuredProducts.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return featuredProducts;
}

export default buildFeaturedProducts;
