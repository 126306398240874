/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { v4 as uuidv4 } from 'uuid';

import { Block, PageSectionArea } from '@interfaces';

import buildBlockList from './buildBlockList';

function getColumnClasses(columnSpan: number): string {
  if (columnSpan === 6) return 'col-span-12 lg:col-span-6';
  return 'col-span-12';
}

async function buildPageSectionAreas(block: any): Promise<PageSectionArea[]> {
  if (!block?.areas) return [];

  const areas: PageSectionArea[] = [];

  for (const area of block.areas) {
    const builtBlocks = await Promise.all(await buildBlockList({ blocks: area.blocks }));

    if (builtBlocks.length) {
      areas.push({
        key: uuidv4(),
        blocks: builtBlocks.filter((builtBlock: Block) => !!builtBlock),
        columnClasses: getColumnClasses(area.columnSpan),
      });
    }
  }

  return areas;
}

export default buildPageSectionAreas;
