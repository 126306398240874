import { Form, FormDefaultValues, FormPage } from '@interfaces/forms';

import { getCustomId } from '@utilities';
import buildFormDefaultValues from './buildFormDefaultValues';
import buildFormPages from './buildFormPages';
import getFormProps from '../../getFormProps';
import buildTheme from '../buildTheme';
import buildImage from '../buildImage';

async function buildForm(props: any): Promise<Form | undefined> {
  if (!props?.formID) return undefined;

  const formProps = await getFormProps(props.formID);

  if (!formProps) return undefined;

  const pages: FormPage[] = buildFormPages(formProps.pages);
  const defaultValues: FormDefaultValues | undefined = buildFormDefaultValues(pages);

  const form: Form = {
    defaultValues,
    fieldIndicationType: formProps.fieldIndicationType || 'MarkMandatoryFields',
    hideFieldValidation: formProps.hideFieldValidation,
    id: formProps.id,
    indicator: formProps.indicator || '*',
    messageOnSubmit: formProps.messageOnSubmit || 'Thank you',
    name: formProps.name,
    nextLabel: formProps.nextLabel || 'Continue',
    notificationRecipients: props.notificationRecipients,
    pages,
    postFormSubmissionsToCMS: props.postFormSubmissionsToCMS,
    previousLabel: formProps.previousLabel || 'Back',
    showValidationSummary: formProps.showValidationSummary,
    submitLabel: formProps.submitLabel || 'Submit',
    thankYouTitle: props.thankYouTitle || 'Thank you',
  };

  if (props.caption) form.caption = props.caption;
  if (props.captionImage) form.captionImage = buildImage(props.captionImage, props.altText);
  if (props.title) form.title = props.title;
  if (props.summary) form.summary = props.summary;

  if (props.thankYouCaption) form.thankYouCaption = props.thankYouCaption;
  if (props.thankYouTitle) form.thankYouTitle = props.thankYouTitle;
  if (props.thankYouSummary) form.thankYouSummary = props.thankYouSummary;

  if (props.blockTheme) form.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  if (props.customID) form.customID = getCustomId(props.customID);

  return form;
}

export default buildForm;
