import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default: 'flex flex-wrap justify-between pt-6 my-4 mt-6 border-t border-gray-300 border-dotted',
  },
  ul: 'flex flex-wrap space-x-3',
  li: 'flex items-center',
  link: 'text-sm transition text-click-para hover:text-click-orange',
  copyright: 'text-sm text-click-para',
};

export default new ClassesBuilder(classesConfig);
