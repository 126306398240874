import NextLink from 'next/link';

import { MenuGroup as MenuGroupInterface } from '@interfaces';
import { Link } from '@components/Link';

import classes from './MenuGroup.classes';
import { MenuLinks } from './MenuLinks';

export interface MenuGroupProps extends Omit<MenuGroupInterface, 'key'> {}

function MenuGroup({ name, menuGroupUrl, menuLinks }: MenuGroupProps): JSX.Element {
  const hasMenuLinks = menuLinks?.length > 0;
  let menuGroupLink = (
    <Link href={menuGroupUrl || '/'}>
      <span className={classes.link}>{name}</span>
    </Link>
  );

  if (!hasMenuLinks) {
    menuGroupLink = (
      <NextLink href={menuGroupUrl} data-element-click={`link[${name}]_MenuGroup[${name}]`}>
        {menuGroupLink}
      </NextLink>
    );
  }

  return (
    <>
      {menuGroupLink}
      {hasMenuLinks && <MenuLinks menuLinks={menuLinks} parent={name} />}
    </>
  );
}

export default MenuGroup;
