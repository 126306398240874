import NextLink from 'next/link';

import { MenuLink } from '@interfaces';

import classes from './FooterBottom.classes';

export interface FooterBottomProps {
  companyName: string;
  legalLinks: MenuLink[];
}

function FooterBottom({ companyName, legalLinks }: FooterBottomProps): JSX.Element {
  return (
    <div className={classes.container}>
      {legalLinks?.length ? (
        <ul className={classes.ul}>
          {legalLinks.map((legalLink: MenuLink) => (
            <li key={legalLink.key} className={classes.li}>
              <NextLink
                href={legalLink.url}
                className={classes.link}
                data-element-click={`link[${legalLink.name}]_FooterBottom`}
              >
                {legalLink.name}
              </NextLink>
            </li>
          ))}
        </ul>
      ) : null}
      <div className={classes.copyright}>
        &copy; {companyName} {new Date().getFullYear()}
      </div>
    </div>
  );
}

export default FooterBottom;
