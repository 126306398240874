import NextImage from 'next/image';
import NextLink from 'next/link';
import { useEffect, useState, useRef } from 'react';

import { Image } from '@interfaces';
import { useDesktopSize } from '@utilities';
import { Icon } from '@components/Icon';

import { MainNavigation, MainNavigationProps } from './MainNavigation';

import classes from './Header.classes';

export interface HeaderProps {
  siteName: string;
  siteLogo?: Image;
  mainNavigation?: MainNavigationProps;
  telephoneNumber?: string;
  telephoneUrl?: string;
}

function Header({ siteName, siteLogo, mainNavigation, telephoneNumber, telephoneUrl }: HeaderProps): JSX.Element {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeScrolled = window.scrollY > 50;

      if (shouldBeScrolled !== isScrolled) {
        setIsScrolled(shouldBeScrolled);
        setIsScrolling(true);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setIsScrolling(false);
        }, 100);
      }
    };

    // Set initial scroll state without triggering transitions
    setIsScrolled(window.scrollY > 50);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isScrolled]);

  let logo = <h1>{siteName}</h1>;

  if (siteLogo?.src) {
    logo = (
      <div className={classes.imageContainer}>
        <NextImage
          src={siteLogo.src}
          alt={siteLogo.alt || ''}
          style={{ objectFit: 'contain' }}
          width={0}
          height={0}
          sizes="100vw"
          className={classes.image}
          priority
        />
      </div>
    );
  }

  const isDesktop = useDesktopSize();

  return (
    <header
      className={`
        ${classes.header} 
        ${isScrolled ? classes.headerScrolled : ''} 
        ${isScrolling ? classes.headerScrolling : ''}
      `}
    >
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <NextLink href="/" data-element-click="logo_Header">
            {logo}
          </NextLink>
        </div>
        <div className={classes.mainNavigationContainer}>
          {mainNavigation && (
            <MainNavigation {...mainNavigation} telephoneNumber={telephoneNumber} telephoneUrl={telephoneUrl} />
          )}
        </div>
        {isDesktop && telephoneNumber && (
          <NextLink href="/contact-us">
            <div className={classes.telephoneNumber}>
              <Icon icon="fa fa-phone" className={classes.telephoneIcon} />
              {telephoneNumber}
            </div>
          </NextLink>
        )}
      </div>
    </header>
  );
}

export default Header;
