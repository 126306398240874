import { Button } from '@components/Button';
import { Icon } from '@components/Icon';

import classes from './PreviewNotification.classes';

function PreviewNotification(): JSX.Element {
  return (
    <div className={classes.previewMode}>
      <div className={classes.notice}>
        <Icon icon="fas fa-eyes fa-flip-horizontal" className={classes.icon} />
        <p className={classes.p}>You are viewing this site in Preview Mode.</p>
      </div>
      <Button href="/api/exit-preview" size="small">
        Exit
      </Button>
    </div>
  );
}

export default PreviewNotification;
