import { Image } from '@interfaces';

function buildImage(props: any, alt?: string): Image | undefined {
  if (!props?.url) return undefined;

  return {
    src: `${process.env.MEDIA_URL}${props.url}`,
    alt: alt || props.name,
    width: props.width || 0,
    height: props.height || 0,
  };
}

export default buildImage;
