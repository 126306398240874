import { v4 as uuidv4 } from 'uuid';

import { Review, ReviewList } from '@interfaces';
import { getCustomId } from '@utilities';

import buildPagination from './buildPagination';
import getReviews from '../getReviews';
import buildTheme from './buildTheme';
import buildButton from './buildButton';
import buildLink from './buildLink';

async function buildReviewList(props: any): Promise<ReviewList | null> {
  const reviews = await getReviews();
  if (reviews?.items.length < 1) return null;

  const builtReviews: Review[] = reviews.items.map((review: any) => {
    const builtReview: Review = {
      key: uuidv4(),
      reviewerName: review.reviewerName,
      reviewText: review.reviewText,
    };

    if (review.reviewRating) builtReview.reviewRating = review.reviewRating;
    if (review.reviewerCaption) builtReview.reviewerCaption = review.reviewerCaption;

    return builtReview;
  });

  const reviewList: ReviewList = {
    blockIdentifier: props.title ? `ReviewList[${props.title}]` : 'ReviewList',
    reviews: builtReviews,
    pagination: buildPagination({
      pageSize: reviews.pageSize,
      pageNumber: reviews.pageNumber,
      totalPages: reviews.totalPages,
      totalItems: reviews.totalItems,
    }),
  };

  if (props.customID) reviewList.customID = getCustomId(props.customID);
  if (props.caption) reviewList.caption = props.caption;
  if (props.title) reviewList.title = props.title;
  if (props.summary) reviewList.summary = props.summary;

  const button = buildButton(props.button);
  if (button) reviewList.button = button;

  const link = buildLink(props.link);
  if (link) reviewList.link = link;

  if (props.blockTheme) reviewList.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return reviewList;
}

export default buildReviewList;
