import { TwoColumnRichTextBlock } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildTheme from './buildTheme';
import buildImage from './buildImage';
import buildLink from './buildLink';

function buildTwoColumnRichTextBlock(props: any): TwoColumnRichTextBlock | null {
  if (!props?.leftRichText.trim() || !props?.rightRichText.trim()) return null;

  const twoColumnRichTextBlock: TwoColumnRichTextBlock = {
    blockIdentifier: props.leftTitle ? `TwoColumnRichTextBlock[${props.leftTitle}]` : 'TwoColumnRichTextBlock',
    leftRichText: props.leftRichText.trim(),
    rightRichText: props.rightRichText.trim(),
  };

  if (props.leftCaption) twoColumnRichTextBlock.leftCaption = props.leftCaption;
  if (props.leftCaptionImage)
    twoColumnRichTextBlock.leftCaptionImage = buildImage(props.leftCaptionImage, props.leftCaptionImageAltText);
  if (props.leftTitle) twoColumnRichTextBlock.leftTitle = props.leftTitle;

  if (props.rightCaption) twoColumnRichTextBlock.rightCaption = props.rightCaption;
  if (props.rightCaptionImage)
    twoColumnRichTextBlock.rightCaptionImage = buildImage(props.rightCaptionImage, props.rightCaptionImageAltText);
  if (props.rightTitle) twoColumnRichTextBlock.rightTitle = props.rightTitle;

  const button = buildButton(props.button);
  if (button) twoColumnRichTextBlock.button = button;

  const link = buildLink(props.link);
  if (link) twoColumnRichTextBlock.link = link;

  if (props.blockTheme) twoColumnRichTextBlock.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  if (props.customID) twoColumnRichTextBlock.customID = getCustomId(props.customID);

  return twoColumnRichTextBlock;
}

export default buildTwoColumnRichTextBlock;
