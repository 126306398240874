/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { v4 as uuidv4 } from 'uuid';

import { PageSection } from '@interfaces';

import buildPageSectionSettings from './buildPageSectionSettings';
import buildPageSectionAreas from './buildPageSectionAreas';

async function buildPageSections(props: any): Promise<PageSection[]> {
  if (!props?.sections || props.sections.length < 1) return [];

  const sections: PageSection[] = [];

  let index = 0;

  for (const section of props.sections) {
    const previousBlock = index > 0 ? props.sections[index - 1] : null;
    const settings = buildPageSectionSettings(section, previousBlock);
    const areas = await Promise.all(await buildPageSectionAreas(section));

    if (areas.length) {
      sections.push({ key: uuidv4(), settings, areas, hasDarkBackground: section.alias === 'fullWidthLayoutDark' });
    }

    index += 1;
  }

  return sections;
}

export default buildPageSections;
