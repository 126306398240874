import { ClassesBuilder } from '@utilities';

const classesConfig = {
  ul: {
    default: 'z-20 grid',
    '2xs': '2xs:grid-cols-2',
    md: 'md:grid-cols-3',
    lg: 'lg:hidden lg:group-hover:grid lg:grid-cols-1 lg:w-72 lg:-translate-x-1/2 lg:absolute lg:top-12 lg:right-0 lg:left-1/2 lg:bg-white lg:rounded-xl lg:shadow-xl lg:p-6',
  },
  li: 'flex items-center row-nowrap',
  link: {
    default:
      'flex items-center w-full gap-2 p-2 text-sm font-semibold capitalize transition bg-transparent text-click-blue hover:text-click-orange hover:bg-click-grey hover:rounded-md',
    lg: 'lg:text-base',
  },
  icon: 'text-xl text-click-orange fa-fw',
};

export default new ClassesBuilder(classesConfig);
