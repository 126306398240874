import { Promotion } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildImage from './buildImage';
import buildTheme from './buildTheme';
import buildLink from './buildLink';

function buildPromotion(props: any): Promotion | null {
  if (!props?.title) return null;

  const promotion: Promotion = {
    blockIdentifier: `Promotion[${props.title}]`,
    title: props.title,
  };

  if (props.caption) promotion.caption = props.caption;
  if (props.captionImage) promotion.captionImage = buildImage(props.captionImage, props.altText);
  if (props.summary) promotion.summary = props.summary;
  if (props.customID) promotion.customID = getCustomId(props.customID);

  const image = buildImage(props.image, props.promotionImageAltText);
  if (image) promotion.image = image;

  const button = buildButton(props.button);
  if (button) promotion.button = button;

  const link = buildLink(props.link);
  if (link) promotion.link = link;

  if (props.blockTheme) promotion.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return promotion;
}

export default buildPromotion;
