import { Footer as FooterInterface } from '@interfaces';

import { ContactLinks } from '@components/ContactLinks';
import { SocialLinks } from '@components/SocialLinks';
import { FooterMenuGroups } from './FooterMenuGroups';
import { FooterBottom } from './FooterBottom';
import { FooterLogos } from './FooterLogos';

import classes from './Footer.classes';

export interface FooterProps extends FooterInterface {}

function Footer({ contactLinks, companyName, legalLinks, logos, menuGroups, socialLinks }: FooterProps): JSX.Element {
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {menuGroups?.length && <FooterMenuGroups menuGroups={menuGroups} />}
        {logos?.length ? <FooterLogos logos={logos} /> : null}
        <div className={classes.contactLinksContainer}>
          {contactLinks?.length ? <ContactLinks parent="Footer" /> : null}
          {socialLinks?.length ? <SocialLinks socialLinks={socialLinks} parent="Footer" /> : null}
        </div>
        <FooterBottom legalLinks={legalLinks} companyName={companyName} />
      </div>
    </footer>
  );
}

export default Footer;
