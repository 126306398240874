import { Theme } from '@interfaces';

function buildTheme(name?: string): Theme {
  if (name === 'manage') {
    return {
      name,
      background: 'bg-click-manage',
      backgroundHover: 'hover:bg-click-blue',
      backgroundHoverAlt: 'hover:bg-click-manage',
      checkbox: 'checked:bg-click-manage checked:border-click-manage',
      color: 'text-click-manage',
      colorHover: 'hover:text-click-blue',
      colorHoverAlt: 'hover:text-click-manage',
      curve: 'bg-click-curve-manage',
      logoPath: '/assets/logo-horizontal-manage.svg',
      marker: 'marker:text-click-manage',
      radio: 'checked:border-click-manage checked:bg-click-manage checked:outline-click-manage',
      titleText: 'text-click-blue',
      bodyText: 'text-click-para',
      border: 'border-click-manage',
    };
  }

  if (name === 'digital') {
    return {
      name,
      background: 'bg-click-digital',
      backgroundHover: 'hover:bg-click-blue',
      backgroundHoverAlt: 'hover:bg-click-digital',
      checkbox: 'checked:bg-click-digital checked:border-click-digital',
      color: 'text-click-digital',
      colorHover: 'hover:text-click-blue',
      colorHoverAlt: 'hover:text-click-digital',
      curve: 'bg-click-curve-digital',
      logoPath: '/assets/logo-horizontal-digital.svg',
      marker: 'marker:text-click-digital',
      radio: 'checked:border-click-digital checked:bg-click-digital checked:outline-click-digital',
      titleText: 'text-click-blue',
      bodyText: 'text-click-para',
      border: 'border-click-digital',
    };
  }

  if (name === 'marketing') {
    return {
      name,
      background: 'bg-click-marketing',
      backgroundHover: 'hover:bg-click-blue',
      backgroundHoverAlt: 'hover:bg-click-marketing',
      checkbox: 'checked:bg-click-marketing checked:border-click-marketing',
      color: 'text-click-marketing',
      colorHover: 'hover:text-click-blue',
      colorHoverAlt: 'hover:text-click-marketing',
      curve: 'bg-click-curve-marketing',
      logoPath: '/assets/logo-horizontal-marketing.svg',
      marker: 'marker:text-click-marketing',
      radio: 'checked:border-click-marketing checked:bg-click-marketing checked:outline-click-marketing',
      titleText: 'text-click-blue',
      bodyText: 'text-click-para',
      border: 'border-click-marketing',
    };
  }

  return {
    name: 'default',
    background: 'bg-click-orange',
    backgroundHover: 'hover:bg-click-blue',
    backgroundHoverAlt: 'hover:bg-click-orange',
    checkbox: 'checked:bg-click-orange checked:border-click-orange',
    color: 'text-click-orange',
    colorHover: 'hover:text-click-blue',
    colorHoverAlt: 'hover:text-click-orange',
    curve: 'bg-click-curve-orange',
    logoPath: '',
    marker: 'marker:text-click-orange',
    radio: 'checked:border-click-orange checked:bg-click-orange checked:outline-click-orange',
    titleText: 'text-click-blue',
    bodyText: 'text-click-para',
    border: 'border-click-orange',
  };
}

export default buildTheme;
