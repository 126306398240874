import { ClassesBuilder } from '@utilities';

const classesConfig = {
  previewMode:
    'fixed bottom-0 left-0 right-0 py-4 px-6 space-x-8 bg-black bg-opacity-80 text-white z-10 flex items-center justify-center rounded-t-3xl',
  notice: 'flex items-center justify-start',
  icon: 'text-2xl mr-4',
  p: 'text-white mb-0 font-semibold',
};

export default new ClassesBuilder(classesConfig);
