import { ClassesBuilder } from '@utilities';

const classesConfig = {
  footer: {
    default: 'relative overflow-hidden px-6 py-16 bg-click-grey',
    xs: 'xs:py-16',
    sm: 'sm:py-20 sm:px-8',
  },
  container: 'container mx-auto',
  contactLinksContainer: {
    default: 'flex flex-col gap-4',
    md: 'md:mb-10',
  },
};

export default new ClassesBuilder(classesConfig);
