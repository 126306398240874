import { FooterLogo } from '@interfaces';
import buildImage from './buildImage';

function buildFooterLogos(props: any): FooterLogo[] {
  if (!props?.siteLogo && !props?.affiliateLogoImage) return [];
  const logos: FooterLogo[] = [];

  if (props?.siteLogo) {
    logos.push({
      image: props.siteLogo,
      url: '/',
      target: '_self',
    });
  }

  if (props?.affiliateLogoImage) {
    const affiliateImage = buildImage(props.affiliateLogoImage, props.affiliateLogoImageAltText);

    if (affiliateImage) {
      logos.push({
        image: affiliateImage,
        url: props.affiliateLogoUrl?.url,
        target: props.affiliateLogoUrl?.target,
        alt: props.affiliateLogoImageAltText,
      });
    }
  }

  return logos;
}

export default buildFooterLogos;
