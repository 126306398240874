import { Link } from '@components/Link';
import { ProductsMenuGroup as ProductsMenuGroupInterface } from '@interfaces';

import { ProductGroupsList } from './ProductGroupsList';

import classes from './ProductsMenuGroup.classes';

export interface ProductsMenuGroupProps extends ProductsMenuGroupInterface {}

function ProductsMenuGroup({ name, url, viewAllLinkText, productGroups }: ProductsMenuGroupProps): JSX.Element | null {
  if (!productGroups?.length) return null;

  return (
    <>
      <Link href={url || '/'}>
        <span className={classes.heading}>{name}</span>
      </Link>
      <ProductGroupsList productGroups={productGroups} viewAllLinkText={viewAllLinkText} viewAllUrl={url} />
    </>
  );
}

export default ProductsMenuGroup;
