import { v4 as uuidv4 } from 'uuid';

import { ImagePromotion, ImagePromotionItem } from '@interfaces';
import { getCustomId } from '@utilities';

import buildTheme from './buildTheme';
import buildImage from './buildImage';
import buildLink from './buildLink';
import buildButton from './buildButton';

function buildImagePromotion(props: any): ImagePromotion | null {
  if (!props?.items || props.items.length < 1) return null;

  const imagePromotion: ImagePromotion = {
    blockIdentifier: props.title ? `ImagePromotion[${props.title}]` : 'ImagePromotion',
    columns: parseInt(props.columns, 10),
    items: props.items.map((item: any) => {
      const imagePromotionItem: ImagePromotionItem = {
        key: uuidv4(),
        title: item.content.title,
        summary: item.content.summary,
      };

      if (item.content.image) imagePromotionItem.image = buildImage(item.content.image, item.content.altText);
      if (item.content.link) imagePromotionItem.link = buildLink(item.content.link);
      if (item.content.imageLink) imagePromotionItem.imageLink = buildLink(item.content.imageLink);

      return imagePromotionItem;
    }),
  };

  if (props.customID) imagePromotion.customID = getCustomId(props.customID);
  if (props.caption) imagePromotion.caption = props.caption;
  if (props.captionImage) imagePromotion.captionImage = buildImage(props.captionImage, props.altText);
  if (props.title) imagePromotion.title = props.title;

  const button = buildButton(props.button);
  if (button) imagePromotion.button = button;

  const link = buildLink(props.link);
  if (link) imagePromotion.link = link;

  if (props.blockTheme) imagePromotion.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return imagePromotion;
}

export default buildImagePromotion;
