import { MenuLink } from '@interfaces';

function buildLegalLinks(props: any): MenuLink[] {
  if (!props?.legalNavigation?.menuGroups || !props?.legalNavigation?.menuGroups[0]?.menuLinks) return [];

  const links: MenuLink[] = [];

  links.push(...props.legalNavigation.menuGroups[0].menuLinks);

  return links;
}

export default buildLegalLinks;
