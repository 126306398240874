import NextImage from 'next/image';
import NextLink from 'next/link';

import { FooterLogo } from '@interfaces';

import classes from './FooterLogos.classes';

export interface FooterLogosProps {
  logos: FooterLogo[];
}

function FooterLogos({ logos }: FooterLogosProps): JSX.Element {
  return (
    <div className={classes.container}>
      {logos.map((logo) => {
        let image = (
          <div className={classes.imageContainer}>
            <NextImage
              src={logo.image.src}
              alt={logo.image.alt || ''}
              width={logo.image.width}
              height={logo.image.height}
              sizes="100vw"
              quality={75}
              className={classes.image}
            />
          </div>
        );

        if (logo.url && logo.target === '_self') {
          image = (
            <NextLink href={logo.url} data-element-click={`logo[${logo.image.alt}]_FooterLogos`}>
              {image}
            </NextLink>
          );
        }

        if (logo.url && logo.target !== '_self') {
          image = (
            <a
              href={logo.url}
              target={logo.target || '_blank'}
              rel="noreferrer"
              data-element-click={`logo[${logo.image.alt}]_FooterLogos`}
            >
              {image}
            </a>
          );
        }

        return (
          <div key={logo.image.src} className={classes.logoContainer}>
            {image}
          </div>
        );
      })}
    </div>
  );
}

export default FooterLogos;
