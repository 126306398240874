import { VacancyList } from '@interfaces';
import { getCustomId } from '@utilities';

import buildImage from './buildImage';
import buildTheme from './buildTheme';

function buildVacancyList(props: any): VacancyList | null {
  if (!props?.title) return null;

  const vacancies =
    props.vacancies.map((vacancy: any) => ({
      key: vacancy.key,
      jobTitle: vacancy.jobTitle,
      location: vacancy.location,
      department: vacancy.department,
      date: vacancy.date || null,
      url: vacancy.url,
    })) || [];

  const vacancyList: VacancyList = {
    blockIdentifier: props.title ? `VacancyList[${props.title}]` : 'VacancyList',
    vacancies,
  };

  if (props.caption) vacancyList.caption = props.caption;
  if (props.captionImage) vacancyList.captionImage = buildImage(props.captionImage, props.altText);
  if (props.title) vacancyList.title = props.title;
  if (props.summary) vacancyList.summary = props.summary;
  if (props.customID) vacancyList.customID = getCustomId(props.customID);

  if (props.blockTheme) vacancyList.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return vacancyList;
}

export default buildVacancyList;
