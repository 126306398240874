import { v4 as uuidv4 } from 'uuid';

import { LabelledList, LabelledListItem } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildTheme from './buildTheme';
import buildImage from './buildImage';
import buildLink from './buildLink';

function buildLabelledList(props: any): LabelledList | null {
  if (!props?.title || !props?.items || props.items.length < 1) return null;

  const items = props.items.map((item: any) => {
    const labelledListItem: LabelledListItem = {
      key: uuidv4(),
      label: item.content.label,
    };

    if (item.content.title) labelledListItem.title = item.content.title;
    if (item.content.summary) labelledListItem.summary = item.content.summary;

    return labelledListItem;
  });

  const labelledList: LabelledList = {
    blockIdentifier: props.title ? `LabelledList[${props.title}]` : 'LabelledList',
    items,
  };

  if (props.caption) labelledList.caption = props.caption;
  if (props.captionImage) labelledList.captionImage = buildImage(props.captionImage, props.altText);
  if (props.title) labelledList.title = props.title;
  if (props.summary) labelledList.summary = props.summary;
  if (props.customID) labelledList.customID = getCustomId(props.customID);

  const button = buildButton(props.button);
  if (button) labelledList.button = button;

  const link = buildLink(props.link);
  if (link) labelledList.link = link;

  if (props.blockTheme) labelledList.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return labelledList;
}

export default buildLabelledList;
