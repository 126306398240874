import { Biography, BioList } from '@interfaces';
import { getCustomId } from '@utilities';

import buildButton from './buildButton';
import buildImage from './buildImage';
import buildTheme from './buildTheme';

function buildBioList(props: any): BioList | null {
  if (!props?.biographies || props.biographies.length < 1) return null;

  const bioList: BioList = {
    blockIdentifier: `BioList[${props.title}]`,
    title: props.title,
    biographies: [],
  };

  if (props.caption) bioList.caption = props.caption;
  if (props.captionImage) bioList.captionImage = buildImage(props.captionImage, props.altText);
  if (props.customID) bioList.customID = getCustomId(props.customID);

  const biographies = props.biographies.map((biography: any) => {
    const builtBiography: Biography = {
      key: biography.key,
      jobTitle: biography.jobTitle,
      name: biography.name,
      url: biography.url,
      bioType: biography.bioType,
    };

    if (biography.url) builtBiography.url = biography.url;

    const image = buildImage(biography.image);
    if (image) builtBiography.image = image;

    return builtBiography;
  });

  if (biographies) bioList.biographies = biographies;

  const button = buildButton(props.button);
  if (button) bioList.button = button;

  if (props.blockTheme) bioList.blockTheme = buildTheme(props.blockTheme?.label.toLowerCase());

  return bioList;
}

export default buildBioList;
