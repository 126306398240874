import { SocialLink } from '@interfaces';

function buildSocialLinks(props: any): SocialLink[] {
  if (!props?.socialMediaAccounts.length) return [];

  return props.socialMediaAccounts.map((socialMediaAccount: any) => ({
    icon: `fab fa-${socialMediaAccount.name.toLowerCase()}`,
    url: socialMediaAccount.url,
    name: socialMediaAccount.name,
  }));
}

export default buildSocialLinks;
