import { useContext } from 'react';

import { ContactLink } from '@interfaces';
import { Icon } from '@components/Icon';
import { GlobalData } from '@contexts';

import classes from './ContactLinks.classes';

export interface ContactLinksProps {
  orientation?: 'vertical' | 'horizontal';
  parent?: string;
}

function ContactLinks({ orientation = 'horizontal', parent }: ContactLinksProps): JSX.Element {
  const globalData = useContext(GlobalData);
  const blockIdentifier = parent ? `ContactLinks[${parent}]` : 'ContactLinks';
  const listClass = orientation === 'horizontal' ? classes.horizontal : classes.vertical;

  return (
    <ul className={listClass}>
      {globalData.contactLinks.map((contactLink: ContactLink) => (
        <li key={contactLink.url} className={classes.li}>
          <a
            href={contactLink.url}
            className={classes.link}
            target="_blank"
            rel="noreferrer"
            data-element-click={`link[${contactLink.name}]_${blockIdentifier}`}
          >
            <Icon icon={contactLink.icon || 'fak fa-click-dealer'} className={classes.icon} />
            {contactLink.name}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default ContactLinks;
