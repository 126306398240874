import { ClassesBuilder } from '@utilities';

const classesConfig = {
  nav: {
    default: 'grid grid-cols gap-6 mb-8',
    '2xs': '2xs:grid-cols-2 2xs:gap-4 2xs:mb-10',
    sm: 'sm:grid-cols-3 sm:gap-6 sm:mb-10',
    xl: 'xl:grid-cols-6',
  },
  header: 'text-lg font-semibold text-click-blue mb-2',
  li: 'flex items-center mb-2',
  link: 'text-sm text-click-para hover:text-click-orange transition',
};

export default new ClassesBuilder(classesConfig);
