import { ClassesBuilder } from '@utilities';

const classesConfig = {
  heading: {
    default: 'relative hidden mb-2 text-xs font-semibold uppercase cursor-pointer text-click-text',
    lg: 'lg:flex lg:items-center lg:h-12 lg:px-4 lg:text-lg lg:font-bold lg:text-click-blue lg:hover:text-click-orange lg:transition lg:normal-case lg:mb-0',
  },
};

export default new ClassesBuilder(classesConfig);
