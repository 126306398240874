import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: 'fixed bottom-0 w-full z-50 bg-gray-200 p-4 box-border',
  cookieBanner: {
    default: 'mx-8 py-2',
    xs: 'xs:mx-12',
    sm: 'sm:mx-18',
    md: 'md:mx-24',
    lg: 'lg:mx-32',
    xl: 'xl:mx-40',
  },
  acceptButton: 'py-2 px-10 bg-[#62B962] text-white rounded-md',
};

export default new ClassesBuilder(classesConfig);
